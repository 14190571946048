.border-validated {
    border: 1px solid #388e3c !important;
}
.border-primary {
    border: 1px solid #14649D !important;
}

.border-cancelled {
    border: 1px solid #c62828 !important;
}

.border-ongoing {
    border: 1px solid #f57c00 !important;
}

.label-validated {
    color: white !important;
    background-color: #388e3c !important;
}

.label-cancelled {
    color: white !important;
    background-color: #c62828 !important;
}

.label-ongoing {
    color: white !important;
    background-color: #f57c00 !important;
}

.label-primary {
    color: white !important;
    background-color: #14649D !important;
}